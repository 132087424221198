import * as React from "react";
import { get } from "lodash";
import { Formik } from "formik";

import { VideoScriptDetail } from "../../../../../components";
import { VideoScript } from "../../../../../interfaces";

import { validationSchema } from "./validations";
import { useComplianceChecker } from "../../../../../utils";
import useSocialCoachSession from "../../../../../utils/useSocialCoachSession";
import { useCallback, useEffect, useMemo } from "react";

export interface OwnProps {
  videoScript?: VideoScript;
  pageErrors?: string[];

  disabled?: boolean;
  updatingHandler: (values: any) => void;
  validationHandler: (isValid: boolean) => void;
}

type Props = OwnProps;

const VideoScriptFormFC: React.FC<Props> = ({ videoScript, disabled = false, updatingHandler, validationHandler }) => {
  const { playerId } = useSocialCoachSession();
  const effectivePlayerId = useMemo(() => {
    return playerId || videoScript?.playerId;
  }, [playerId, videoScript?.playerId]);

  const { findBlockedWords, findFlaggedWords } = useComplianceChecker({ playerId: effectivePlayerId });
  const [blockedWordsMessage, setBlockedWordsMessage] = React.useState<string>();
  const [flaggedWordsMessage, setFlaggedWordsMessage] = React.useState<string>();

  const validateContent = useCallback(
    (text: string) => {
      const blockedWords = findBlockedWords(text).join(", ");
      if (blockedWords.length > 0) setBlockedWordsMessage(`Blocked words detected: ${blockedWords}`);
      else setBlockedWordsMessage("");

      const flaggedWords = findFlaggedWords(text).join(", ");
      if (flaggedWords.length > 0) setFlaggedWordsMessage(`Flagged words detected: ${flaggedWords}`);
      else setFlaggedWordsMessage("");
    },
    [findFlaggedWords, findBlockedWords, setBlockedWordsMessage, setFlaggedWordsMessage]
  );

  useEffect(() => {
    if (videoScript?.content) validateContent(videoScript.content);
  }, [videoScript?.content, validateContent]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      validationSchema={validationSchema}
      initialValues={{
        videoScriptId: get(videoScript, "videoScriptId", null),
        title: get(videoScript, "title", ""),
        content: get(videoScript, "content", ""),
        categories: videoScript?.categories?.map(c => c.name) || [],
        playerId: effectivePlayerId,
      }}
      initialErrors={blockedWordsMessage}
      onSubmit={async () => {
        // Action removed because the client wants
        // to use TitleSection action to save the prompt
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue }) => {
        const errorMessages = Object.values(errors) as string[];
        const warningMessages: string[] = [];
        if (blockedWordsMessage) errorMessages.push(blockedWordsMessage);
        if (flaggedWordsMessage) warningMessages.push(flaggedWordsMessage);
        updatingHandler(values);
        validationHandler(errorMessages.length === 0);
        return (
          <VideoScriptDetail
            messages={{
              error: errorMessages,
              warn: warningMessages,
            }}
            isSubmitting={isSubmitting}
            handleSetFieldValue={(field, value) => {
              if (field == "content") validateContent(value);

              setFieldValue(field, value);
            }}
            disabled={disabled}
            isPlayerForm
            values={values}
          />
        );
      }}
    </Formik>
  );
};

export const VideoScriptForm = VideoScriptFormFC;
