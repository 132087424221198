import * as React from "react";
import { Grid, Button } from "semantic-ui-react";
import copy from "copy-to-clipboard";

import { EmailTag } from "./EmailTag";
import { TagElement } from "../../../../interfaces";

import "./styles.scss";

interface OwnProps {
  title: string;
  emails: TagElement[];

  customStyle?: string;

  onError: (errors: string[]) => void;
  onSuccess: (newEmails: TagElement[]) => void;
}

type Props = OwnProps;

export const DigestEmailSet: React.FC<Props> = ({ title, emails, customStyle = "", onError, onSuccess }) => {
  const validateNewInput = (newValues: TagElement[]): void => {
    onSuccess(newValues);
  };

  const copyKeywords = (): void => {
    copy(
      emails
        .map(keyword => {
          return keyword.displayValue;
        })
        .toString()
    );
  };

  return (
    <Grid className={"digestEmailSetContainer"}>
      <Grid.Row>
        <Grid.Column width={8} className={"keywordSetTitle"}>
          <div className={"dot email"} />
          {emails.length + " Digest Emails Added"}
        </Grid.Column>

        <Grid.Column width={2} floated="right" className={"negativeMarginTop"}>
          <Button
            data-elm-id={`emailsSectionCopyBtn`}
            basic
            content={"Copy"}
            icon={"copy outline"}
            color={"blue"}
            labelPosition={"right"}
            onClick={copyKeywords}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={"keywordSetTagRow"}>
        <EmailTag
          dataElmId={"digestEmailsTags"}
          placeholder={title}
          tags={emails}
          onTagsChanged={validateNewInput}
          onInputError={onError}
          tagStyle={customStyle}
        />
      </Grid.Row>
    </Grid>
  );
};
