import * as React from "react";
import { useIntl } from "react-intl";
import { useFormikContext } from "formik";

import { BasicFormProps } from "../../../../../interfaces";
import { CaptionField, VideoPreviewActionable } from "../../../../../components";

import { PredictionsComponent } from "../../../../PredictionsComponent";

import { BrandPostComponentType, descriptors } from "./descriptors";
import { ThumbnailPreview } from "./styled";
import "./styles.scss";
import { Button } from "semantic-ui-react";
import { useComplianceChecker } from "../../../../../utils";
import { useCallback } from "react";

interface OwnProps extends BasicFormProps {
  playerId: number;
  errors?: string[];
  warnings?: string[];

  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
}

type Props = OwnProps;

/**
 * Represents a CaptionStep
 */
const CaptionStepFC: React.FC<Props> = ({ playerId, handleSetFieldValue, errors, warnings }) => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext();
  const values: any = formik.values;

  const [showAIModal, setShowAIModal] = React.useState<boolean>(false);

  const removeMedia = () => {
    if (handleSetFieldValue) {
      handleSetFieldValue("mediaGroup", undefined);
      handleSetFieldValue("thumbnail", undefined);
    }
  };

  return (
    <>
      <div key={"rePostFormDiv"} className={"whiteFormDiv rePostInfo"}>
        {values.mediaGroup?.media && (
          <Button icon={"trash"} basic circular className={"postMediaSettingsButton"} onClick={removeMedia} />
        )}

        {values.mediaGroup?.media && values.mediaGroup.type !== "IMAGE" ? (
          <VideoPreviewActionable videos={values.mediaGroup?.media!!} />
        ) : values.thumbnail ? (
          <ThumbnailPreview thumbnailurl={values.thumbnail} />
        ) : undefined}
        <CaptionField
          title={formatMessage({ ...descriptors[BrandPostComponentType.message] })}
          error={errors ? errors[0] : ""}
          warning={warnings ? warnings[0] : ""}
          messagePlaceholder={formatMessage({ ...descriptors[BrandPostComponentType.messagePlaceholder] })}
          aiButtonText="Generate Caption"
          currentValue={values.messageToPost}
          dataElmId="rePostCaption"
          disabled={false}
          handleSetFieldValue={handleSetFieldValue}
          setShowAIModal={setShowAIModal}
          hashtags={""}
          id="messageToPost"
        />
        <br />
      </div>

      {showAIModal && (
        <PredictionsComponent
          opened={true}
          rejectHandler={() => {
            setShowAIModal(false);
          }}
          acceptHandler={prediction => {
            setShowAIModal(false);
            handleSetFieldValue!("messageToPost", prediction);
          }}
        />
      )}
    </>
  );
};

export const CaptionStep = CaptionStepFC;
