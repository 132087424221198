import * as React from "react";
import "./styles.scss";
import { Page } from "../../../components/Page";
import { RouteComponentProps } from "react-router-dom";
import { CalendarEvent, TimePeriod } from "../../../interfaces";
import { CalendarEventServices } from "../../../services/CalendarEventServices";
import { EventTile } from "./components/EventTile";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { useEffect, useRef, useState } from "react";
import { DatesSetArg, EventClickArg } from "@fullcalendar/core";
import { HorizontalScrollContainer } from "../../HorizontalScrollContainer";

type Props = RouteComponentProps;

export const CalendarPage: React.FC<Props> = () => {
  const searchParams = new URLSearchParams(location.search);
  const currentViewParam = searchParams.get("view")?.toLowerCase() === "week" ? "dayGridWeek" : "dayGridMonth";
  var currentDateParam = new Date(Number(searchParams.get("date") || ""));
  if (currentDateParam.toString() === "Invalid Date") currentDateParam = new Date();

  const calendarRef = useRef<FullCalendar | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<{ [key: string]: CalendarEvent }>({});
  const [currentDate, setCurrentDate] = useState<Date>(currentDateParam);
  const [currentView, setCurrentView] = useState<string>(currentViewParam);

  const renderEventContent = (event: any) => {
    return <EventTile event={event.event.extendedProps} dense={event.view.type === "dayGridMonth"} />;
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set("view", currentView === "dayGridWeek" ? "week" : "month");
    url.searchParams.set("date", `${+currentDate}`);
    window.history.pushState({}, "", url);

    setIsLoading(true);
    const timePeriod: TimePeriod = currentView === "dayGridMonth" ? "MONTH" : "WEEK";
    CalendarEventServices.getCalendarEvents(currentDate, timePeriod)
      .then(response => {
        setEvents(currentEvents => {
          const newEvents = { ...currentEvents };
          response.content.forEach(event => {
            newEvents[event.id] = event;
          });
          return newEvents;
        });
      })
      .catch(e => console.error(e))
      .finally(() => setIsLoading(false));
  }, [currentView, currentDate]);

  useEffect(() => {
    const url = new URL(window.location.href);

    window.history.pushState({}, "", url);
  }, [currentDate]);

  const handleDatesSet = (event: DatesSetArg) => {
    console.log("Dates set", event);
    setCurrentView(event.view.type);
    if (calendarRef.current) {
      setCurrentDate(calendarRef.current.getApi().getDate());
    }
  };

  const handleDateClick = (event: DateClickArg) => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView("dayGridWeek", event.dateStr);
    }
  };

  const handleEventClick = (event: EventClickArg) => {
    // TODO: Open event modal
    console.log("Event clicked", event);
  };

  return (
    <Page title="Calendar">
      <HorizontalScrollContainer>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          events={Object.values(events).map(e => ({ ...e, title: e.sourceName, date: e.scheduledDateTime }))}
          initialView={currentView}
          fixedWeekCount={false}
          initialDate={currentDate}
          height="auto"
          buttonText={{ today: "Today", month: "Month", week: "Week" }}
          headerToolbar={{ start: "prev,today,next", center: "title", end: "dayGridMonth,dayGridWeek" }}
          dayMaxEventRows={currentView === "dayGridMonth" ? 5 : undefined}
          eventContent={renderEventContent}
          datesSet={handleDatesSet}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
        />
      </HorizontalScrollContainer>
    </Page>
  );
};
