import React from "react";
import styled from "styled-components";
import { ConfigContext } from "../../../../../contexts/appContexts";

import { AppContext } from "../../../../../providers";
import * as DTO from "../../../../../interfaces";
import { coachWithWritePermission } from "../../../../../utils";

import { Tag, Wrapper, Input, TagDelete } from "./styled";

interface Props {
  tags: any[];
  unavailableTags: string[];

  placeholder?: string;
  dataElmId: string;
  tagDeleteIcon?: string;
  addTagOnEnterKeyPressed?: boolean;
  uniquenessTags?: boolean;

  tagDeleteStyle?: string;
  tagStyle?: string;
  inputStyle?: string;
  wrapperStyle?: string;

  onTagsChanged: (tags: any[]) => void;
  onInputChanged?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputRepeatedError: (tagsName: string[]) => void;
}

export const KeywordTag: React.FC<Props> = ({
  tags,
  unavailableTags,
  tagDeleteIcon = "x",
  uniquenessTags = true,
  dataElmId,
  placeholder = "Type some Tags...",
  addTagOnEnterKeyPressed = true,
  tagDeleteStyle,
  tagStyle,
  inputStyle,
  wrapperStyle,
  onTagsChanged,
  onInputChanged,
  onInputRepeatedError,
}) => {
  const [selectedTags, setSelectedTags] = React.useState(tags);
  const [unavailableList, setUnavailableList] = React.useState<string[]>(unavailableTags);

  const input = React.useRef<HTMLInputElement | null>(null);
  const { keywordMaxLength } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(DTO.WebComponents.COMPLIANCE, userContext);

  React.useEffect(() => {
    setSelectedTags(() => tags);
  }, [tags]);

  React.useEffect(() => {
    setUnavailableList(() => selectedTags.map(a => a.displayValue.toLowerCase()).concat(unavailableTags));
  }, [unavailableTags, selectedTags]);

  React.useEffect(() => {
    clearInput();
    onTagsChanged(selectedTags);
  }, [selectedTags]);

  const addTag = (displayValue: string) => {
    setSelectedTags(selected => {
      return selected.concat([
        {
          index: selected.length + 1,
          displayValue: displayValue.trim(),
        },
      ]);
    });
  };

  const onInputKeyUp = (e: any): void => {
    if (withWritePermission) {
      const inputValue = e.target.value ? e.target.value.trim() : "";
      const inputNotEmpty = inputValue !== "";
      if (e.key === "Enter" && inputNotEmpty && addTagOnEnterKeyPressed && validate(inputValue)) {
        addTag(inputValue);
        onInputRepeatedError([]);
      }
    }
  };

  const onInputPaste = (pasteValue: string): void => {
    if (withWritePermission) {
      const inputValues = pasteValue.split(",");

      const errors: string[] = [];
      inputValues.map(value => {
        const inputValue = value.trim();
        const isValid = validateBulk(inputValue);
        const inputNotEmpty = inputValue !== "";
        if (inputNotEmpty && addTagOnEnterKeyPressed && isValid) {
          addTag(inputValue);
        } else if (!isValid) {
          errors.push(inputValue);
        }
      });

      if (errors.length > 0) {
        onInputRepeatedError(errors);
      }
    }
  };

  const inputWillChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onInputChanged && withWritePermission) {
      onInputChanged(e);
    }
  };

  const onInputKeyDown = (e: any): void => {
    if (withWritePermission) {
      const deleteLastTag = () => {
        setSelectedTags(oldTags => {
          return [...oldTags.splice(0, oldTags.length - 1)];
        });
      };

      if (e.key === "Backspace" && e.target.selectionStart === 0) {
        deleteLastTag();
      } else if (e.key !== "Backspace" && e.target.value.length >= keywordMaxLength) {
        e.preventDefault();
      }
    }
  };

  const validate = (newValue: string): boolean => {
    if (!uniquenessTags) {
      return true;
    }

    if (unavailableList.includes(newValue.toLowerCase())) {
      onInputRepeatedError([newValue]);
      return false;
    }

    return true;
  };

  const validateBulk = (newValue: string): boolean => {
    if (!uniquenessTags) {
      return true;
    }

    if (unavailableList.includes(newValue.toLowerCase())) {
      return false;
    }

    return true;
  };

  const clearInput = (): void => {
    input!.current!.value = "";
  };
  const focusInput = (): void => {
    input!.current!.focus();
  };

  const removeTag = (index: number): void => {
    if (withWritePermission) {
      setSelectedTags(oldTags => {
        return [...oldTags.filter(tag => tag.index !== index)];
      });
    }
  };

  const renderTags = (): any => {
    const TagComponent = getTagStyledComponent();
    const Delete = getTagDeleteComponent();
    const DeleteIcon = getDeleteIcon();

    return selectedTags.length > 0
      ? selectedTags.map((tag, index) => (
          <TagComponent key={index}>
            {tag && tag.displayValue}
            <Delete onClick={() => removeTag(tag.index)}>{DeleteIcon}</Delete>
          </TagComponent>
        ))
      : null;
  };

  const renderPlaceholder = (): string | undefined => {
    return selectedTags && selectedTags.length > 0 ? undefined : placeholder;
  };

  const getDeleteIcon = (): string => {
    return tagDeleteIcon;
  };

  const getTagDeleteComponent = () => {
    return tagDeleteStyle
      ? styled(TagDelete)`
          ${tagDeleteStyle}
        `
      : TagDelete;
  };

  const getTagStyledComponent = () => {
    return tagStyle
      ? styled(Tag)`
          ${tagStyle}
        `
      : Tag;
  };

  const getInputWrapperStyledComponent = () => {
    return wrapperStyle
      ? styled(Wrapper)`
          ${wrapperStyle}
        `
      : Wrapper;
  };

  const getInputStyledComponent = () => {
    return inputStyle
      ? styled(Input)`
          ${inputStyle}
        `
      : Input;
  };

  const InputWrapper = getInputWrapperStyledComponent();
  const InputComponent = getInputStyledComponent();
  return (
    <InputWrapper onClick={focusInput}>
      {renderTags()}
      <InputComponent
        ref={input}
        data-elm-id={dataElmId}
        onChange={inputWillChange}
        placeholder={renderPlaceholder()}
        type="text"
        onKeyUp={onInputKeyUp}
        onKeyDown={onInputKeyDown}
        disabled={!withWritePermission}
        onPaste={text => {
          onInputPaste(text.clipboardData.getData("Text"));
          text.preventDefault();
        }}
      />
    </InputWrapper>
  );
};
