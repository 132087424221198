import { client as axios } from "./axiosClient";
import { Constants } from "@socialcoach/services";
import { catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces";

const CALENDAR_EVENT_SERVICE = `${Constants.API_URL}/calendar_events`;

export class CalendarEventServices {
  @catchServiceException()
  static async getCalendarEvents(
    dateTime: Date,
    timePeriod: DTO.TimePeriod,
    page: number = 0,
    size: number = 2000
  ): Promise<DTO.Page<DTO.CalendarEvent>> {
    const url = `${CALENDAR_EVENT_SERVICE}/?dateTime=${dateTime.toISOString()}&timePeriod=${timePeriod}&page=${page}&size=${size}`;
    const { data } = await axios.get<DTO.Page<DTO.CalendarEvent>>(url, { withCredentials: true });
    return data;
  }
}
