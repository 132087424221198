import * as React from "react";
import { Grid, Button, Header } from "semantic-ui-react";
import copy from "copy-to-clipboard";

import { HashTag } from "./HashTag";
import { HashTagElement, TagElement } from "../../../../interfaces";

import "./styles.scss";
import { removeWhiteSpace, useComplianceChecker } from "../../../../utils";
import { HashTagsServices } from "../../../../services";

interface OwnProps {
  title: string;
  customTagStyle?: string;
  customTitleClassName?: string;
  customRowClassName?: string;
  coachId: string;
  addHashTagAutomatically?: boolean;
  onError: (keywords: string[]) => void;
  onSuccess: (newTags: TagElement[]) => void;
  addHashtagOnChange?: (checked: boolean) => void;
}

type Props = OwnProps;

export const HashTagSet: React.FC<Props> = ({
  title,
  customTitleClassName = "hashTagSetTitle",
  customTagStyle = "",
  customRowClassName = "",
  coachId,
  onError,
  onSuccess,
}) => {
  const { blockedPhrases } = useComplianceChecker({ coachId });
  const [updatedHashTagsList, setUpdatedHashTagsList] = React.useState<TagElement[]>([]);

  const hashTagsAsTags = (result: HashTagElement[]): TagElement[] => {
    return result.map((hashtag, index) => {
      const k: TagElement = {
        index,
        displayValue: hashtag.content,
        id: `${index}`,
      };
      return k;
    });
  };

  React.useEffect(() => {
    if (coachId) {
      HashTagsServices.getCoachHashtags(coachId).then(response => {
        const coachHashTags = hashTagsAsTags(response);
        setUpdatedHashTagsList(coachHashTags);
        validateNewInput(coachHashTags);
      });
    }
  }, [coachId]);

  const validateNewInput = (newValues: TagElement[]): void => {
    setUpdatedHashTagsList(newValues);
    onSuccess(newValues);
  };

  const copyKeywords = (): void => {
    copy(
      updatedHashTagsList
        .map(hashtag => {
          return `#${hashtag.displayValue} `;
        })
        .toString()
    );
  };

  return (
    <Grid className={"hashTagSetContainer"}>
      <Grid.Row className={customRowClassName}>
        <Grid.Column width={13}>
          <Header as="h4" className={customTitleClassName}>
            {title}
          </Header>
        </Grid.Column>

        <Grid.Column width={3} floated={"right"} className={"negativeMarginTop"}>
          <Button
            data-elm-id={`hashTagsSectionCopyBtn`}
            basic
            content={"Copy"}
            icon={"copy outline"}
            color={"blue"}
            labelPosition={"right"}
            onClick={copyKeywords}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={"hashTagSetTagRow"}>
        <HashTag
          dataElmId={removeWhiteSpace(title)}
          placeholder={title}
          tags={updatedHashTagsList}
          unavailableTags={blockedPhrases}
          onTagsChanged={validateNewInput}
          onInputRepeatedError={onError}
          tagStyle={customTagStyle}
        />
      </Grid.Row>
    </Grid>
  );
};
