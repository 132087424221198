import * as React from "react";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";

type Props = {};

export const HorizontalScrollContainer: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [hasLeftShadow, setHasLeftShadow] = useState(false);
  const [hasRightShadow, setHasRightShadow] = useState(false);

  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    const updateShadows = () => {
      if (!el) return;

      const scrollLeft = el.scrollLeft;
      const maxScrollLeft = el.scrollWidth - el.clientWidth;

      setHasLeftShadow(scrollLeft > 0);
      setHasRightShadow(scrollLeft < maxScrollLeft);
    };

    updateShadows();
    el.addEventListener("scroll", updateShadows);
    window.addEventListener("resize", updateShadows);

    return () => {
      el.removeEventListener("scroll", updateShadows);
      window.removeEventListener("resize", updateShadows);
    };
  }, []);

  const shadowClassNames = [
    "horizontal-scroll-shadow-wrapper",
    hasLeftShadow ? "has-left-shadow" : "",
    hasRightShadow ? "has-right-shadow" : "",
  ].join(" ");

  return (
    <div className={shadowClassNames}>
      <div className="horizontal-scroll-wrapper" ref={scrollRef}>
        {children}
      </div>
    </div>
  );
};
