import * as React from "react";
import { Grid } from "semantic-ui-react";

import { HashTag } from "./HashTag";
import { TagElement } from "../../../../../interfaces";

import "./styles.scss";
import { removeWhiteSpace, useComplianceChecker } from "../../../../../utils";

interface OwnProps {
  title: string;
  coachId: string;
  defaultHashtagList?: string;
  onError: (keywords: string[]) => void;
  onSuccess: (newTags: TagElement[]) => void;
  addHashtagOnChange?: (checked: boolean) => void;
  customTagStyle: string;
}

type Props = OwnProps;

export const HashTagSet: React.FC<Props> = ({
  title,
  defaultHashtagList,
  coachId,
  customTagStyle,
  onError,
  onSuccess,
}) => {
  const { blockedPhrases } = useComplianceChecker({ coachId });

  const hashTagsAsTags = (hashtagsString?: string): TagElement[] => {
    let hashtagsElements: TagElement[] = [];

    if (hashtagsString) {
      const hashtagsList = hashtagsString.split(" #");
      hashtagsElements = hashtagsList.map((hashtag, index) => {
        const tagElement: TagElement = {
          index,
          displayValue: removeWhiteSpace(hashtag.replace("#", "")),
          id: `${index}`,
        };
        return tagElement;
      });

      return hashtagsElements;
    }

    return hashtagsElements;
  };

  const [tagList, setTagList] = React.useState<TagElement[]>([]);

  React.useEffect(() => {
    setTagList(hashTagsAsTags(defaultHashtagList));
  }, [defaultHashtagList]);

  const validateNewInput = (newValues: TagElement[]): void => {
    onSuccess(newValues);
  };

  return (
    <Grid className={"promptHashTagSetContainer"} data-elm-id={"promptHashtagsSet"}>
      <Grid.Row className={"hashTagSetTagRow"}>
        <HashTag
          dataElmId={"coachPromptHashtags"}
          placeholder={title}
          tagStyle={customTagStyle}
          tags={tagList}
          unavailableTags={blockedPhrases}
          onTagsChanged={validateNewInput}
          onInputRepeatedError={onError}
        />
      </Grid.Row>
    </Grid>
  );
};
