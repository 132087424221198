import * as React from "react";
import { CalendarEvent, SocialNetworksId } from "../../../../../interfaces";
import "./styles.scss";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardMeta,
  Icon,
  SemanticICONS,
  Image,
} from "semantic-ui-react";
import { SocialNetworksSection } from "../../../../../components";
import { useSocialNetworks } from "../../../../../reducers";
import pluralize from "pluralize";
import moment from "moment";

type Props = {
  event: CalendarEvent;
  dense?: boolean;
};

export const EventTile: React.FC<Props> = ({ event, dense = false }) => {
  const eventTypeDetailMappings: { [key: string]: { label: string; icon: SemanticICONS } } = {
    campaign: {
      label: "Campaign",
      icon: "bullhorn",
    },
    content_page: {
      label: "Video Page",
      icon: "file video",
    },
    video_project: {
      label: "Video Project",
      icon: "video",
    },
    prompt: {
      label: "Prompt",
      icon: "sticky note",
    },
    post: {
      label: "Post",
      icon: "map signs",
    },
  };
  const eventTypeDetails = eventTypeDetailMappings[event.sourceType];
  const { label, icon } = eventTypeDetails;
  const { sns } = useSocialNetworks();
  const snIds: SocialNetworksId[] = (event.socialNetworks || "")
    .split("|")
    .filter(sn => sns.includes(sn as SocialNetworksId)) as SocialNetworksId[];

  const thumbnails = (event.thumbnails || "").split("|").filter(Boolean);
  // TODO:
  // 1. What to do if there is more than one thumbnail?
  // 2. Where do we put the three-dot menu?
  // 3. Display percentage of each post's status
  // 4. Display players that are in the event

  const scheduledDateTime = new Date(event.scheduledDateTime);
  const timeTextFormat = scheduledDateTime.getMinutes() === 0 ? "ha" : "h:mma";
  const timeText = moment(scheduledDateTime).format(timeTextFormat);

  if (dense) {
    return (
      <div className={`eventTile dense ${event.sourceType}`}>
        <Icon name={icon} />
        <span className="eventTime">{timeText}</span>
        <span className="sourceName">{event.sourceName}</span>
      </div>
    );
  } else {
    return (
      <Card className={`eventTile ${event.sourceType}`} raised>
        {thumbnails.length > 0 && <Image src={thumbnails[0]} wrapped ui={false} />}
        <CardContent>
          <CardHeader>{event.sourceName}</CardHeader>
          <CardMeta>
            <div>
              <Icon name={icon} />
              <span>{label}</span>
            </div>
            <div>
              <Icon name="clock" />
              <span>{timeText}</span>
            </div>
          </CardMeta>
          <CardDescription>
            <SocialNetworksSection actives={snIds} allSocialNetworks={sns} />
          </CardDescription>
        </CardContent>
        <CardContent extra>
          <a>
            <Icon name="map signs" />
            {event.postCount} {pluralize("posts", event.postCount)}
          </a>
        </CardContent>
      </Card>
    );
  }
};
