import * as Yup from "yup";
import { Rules } from "../../../../../utils/rules";

export const validationSchema = Yup.object().shape(
  {
    message: Yup.string().trim().max(Rules.MAX_CONTENT, "Caption is too long").required("Caption is required"),
    blockedWordsInCaption: Yup.string().trim().max(0, "Blocked words found in caption"),
  },
  [["withMessage"]]
);
