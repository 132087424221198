import * as React from "react";
import { Grid, Button, Header, Radio, Form } from "semantic-ui-react";
import copy from "copy-to-clipboard";

import { HashTag } from "./HashTag";
import { HashTagElement, KeywordElement, KeywordsType, TagElement } from "../../../../interfaces";

import "./styles.scss";
import { removeWhiteSpace, useComplianceChecker } from "../../../../utils";
import { HashTagsServices, KeywordServices } from "../../../../services";

interface OwnProps {
  title: string;
  customTagStyle?: string;
  customTitleClassName?: string;
  customRowClassName?: string;
  playerId: number;
  coachId: string;
  asPlayer?: boolean;
  addHashTagAutomatically?: boolean;
  onError: (keywords: string[]) => void;
  onSuccess: (newTags: TagElement[]) => void;
  addHashtagOnChange?: (checked: boolean) => void;
}

type Props = OwnProps;

export const HashTagSet: React.FC<Props> = ({
  title,
  customTitleClassName = "hashTagSetTitle",
  customTagStyle = "",
  customRowClassName = "",
  asPlayer = false,
  playerId,
  coachId,
  addHashTagAutomatically = false,
  onError,
  onSuccess,
  addHashtagOnChange,
}) => {
  const { blockedPhrases } = useComplianceChecker({ playerId, coachId });
  const [updatedHashTagsList, setUpdatedHashTagsList] = React.useState<TagElement[]>([]);

  const hashTagsAsTags = (result: HashTagElement[]): TagElement[] => {
    return result.map((hashtag, index) => {
      const k: TagElement = {
        index,
        displayValue: hashtag.content,
        id: `${index}`,
      };
      return k;
    });
  };

  React.useEffect(() => {
    if (playerId) {
      HashTagsServices.get(playerId).then(response => {
        const playerHashTags = hashTagsAsTags(response);
        setUpdatedHashTagsList(playerHashTags);
        validateNewInput(playerHashTags);
      });
    }
  }, [playerId]);

  const validateNewInput = (newValues: TagElement[]): void => {
    onSuccess(newValues);
  };

  const copyKeywords = (): void => {
    copy(
      updatedHashTagsList
        .map(hashtag => {
          return `#${hashtag.displayValue} `;
        })
        .toString()
    );
  };

  return (
    <Grid className={"hashTagSetContainer"}>
      <Grid.Row className={customRowClassName}>
        <Grid.Column width={13}>
          <Header as="h4" className={customTitleClassName}>
            {title}
          </Header>
        </Grid.Column>
        {!asPlayer ? (
          <Grid.Column width={3} floated={"right"} className={"negativeMarginTop"}>
            <Button
              data-elm-id={`hashTagsSectionCopyBtn`}
              basic
              content={"Copy"}
              icon={"copy outline"}
              color={"blue"}
              labelPosition={"right"}
              onClick={copyKeywords}
            />
          </Grid.Column>
        ) : (
          <Grid.Column width={3} floated={"right"} className={"negativeMarginTop"}>
            <Form.Group inline>
              <Radio
                toggle
                disabled={false}
                label={""}
                className={"settingsLevelLabel"}
                onChange={() => {
                  if (addHashtagOnChange) {
                    addHashtagOnChange(!addHashTagAutomatically);
                  }
                }}
                name={"addHashTagsAutomatically"}
                checked={addHashTagAutomatically}
              />
            </Form.Group>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row className={"hashTagSetTagRow"}>
        <HashTag
          dataElmId={removeWhiteSpace(title)}
          placeholder={title}
          tags={updatedHashTagsList}
          unavailableTags={blockedPhrases}
          onTagsChanged={validateNewInput}
          onInputRepeatedError={onError}
          tagStyle={customTagStyle}
        />
      </Grid.Row>
    </Grid>
  );
};
