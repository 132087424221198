import * as React from "react";
import {
  Grid,
  Header,
  Dropdown,
  Menu,
  Icon,
  Pagination,
  PaginationProps,
  Loader,
  DropdownItemProps,
  Button,
} from "semantic-ui-react";
import { get, map } from "lodash";

import { AppContext } from "../../../../providers";
import {
  SearchFilter,
  OrderType,
  OrderByType,
  Page,
  ReviewStatus,
  AllFilter,
  KeywordsType,
  KeywordElement,
} from "../../../../interfaces";
import { PlayersServices } from "../../../../services";
import { getDateSearchFormat, getUntilDateSearchFormat, useDebounce } from "../../../../utils";

import { CustomDropDownComponent } from "../../../../components";
import { FilterModal } from "./components/FiltersModal";

import "../../../../styling/datepicker.scss";
import "./styles.scss";
import { useMemo } from "react";

const sortStatus = [
  { key: 1, text: "Title", value: "title" },
  { key: 2, text: "Created Date", value: "createdDate" },
];

interface Props {
  /**
   * Title of search section
   */
  title?: string;
  /**
   * Component
   */
  content: JSX.Element;
  /**
   * Handle to refresh content with the result
   */
  refreshData: (result: any[]) => void;

  /**
   * Handle to update export filters
   */
  updateExportFilters?: (filters: SearchFilter, orderType: OrderType) => void;

  /**
   * Search function name
   */
  searchFunction: (
    coachId: string,
    sort: string,
    filters?: SearchFilter | null,
    page?: number | string,
    size?: number
  ) => Promise<Page<any>>;

  exportFunction: () => Promise<void>;

  showSortBy?: boolean;

  defaultFilters?: string[];

  customSort?: DropdownItemProps[] | undefined;
  defaultCustomSort?: string;

  forceRefresh?: boolean;
  multiple?: boolean;
  pageable?: boolean;

  listOfKeywords: KeywordElement[];
}

const SearchSetFC: React.FC<Props> = ({
  title = "Post Feed",
  content,
  pageable = true,
  forceRefresh = false,
  customSort = sortStatus,
  showSortBy = true,
  multiple = false,
  defaultCustomSort = "createdDate",
  listOfKeywords,
  refreshData,
  searchFunction,
  exportFunction,
  updateExportFilters,
}) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  const [playerList, setPlayerList] = React.useState<DropdownItemProps[]>([]);

  const keywordsOptions: DropdownItemProps[] = useMemo(() => {
    return listOfKeywords.map(keyword => {
      return {
        key: keyword.id,
        text: keyword.content,
        value: keyword.id,
      };
    });
  }, [listOfKeywords]);

  const hasSavedKeywords = listOfKeywords.some(k => !!k.id);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [exportLoading, setExportLoading] = React.useState<boolean>(false);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [activePage, setActivePage] = React.useState<number>(1);

  const [order, setOrder] = React.useState<OrderType>("DESC");
  const [searchKeywords, setSearchKeywords] = React.useState<string[] | undefined>(undefined);
  const [playerIds, setPlayerIds] = React.useState<string[]>([]);
  const [orderStatus, setOrderStatus] = React.useState<OrderByType>("createdDate");

  // Review Status
  const [reviewStatusOptions, setReviewStatusOptions] = React.useState<DropdownItemProps[]>([]);
  const [reviewStatus, setReviewStatus] = React.useState<any[]>([]);

  // Filters
  const [displayAllFilters, setDisplayAllFilters] = React.useState<boolean | undefined>(undefined);
  const [selectedFilters, setSelectedFilters] = React.useState<AllFilter | undefined>({
    flagged: false,
    coachPostReviewStatus: undefined,
    branchIds: undefined,
    regionIds: undefined,
    stateIds: undefined,
    from: undefined,
    until: undefined,
    playerIds: [],
    searchKeywords: [],
  });

  const ascOrder = order === "ASC";

  const reviewLabels = {
    [ReviewStatus.REVIEWED]: "Reviewed",
    [ReviewStatus.REQUEST_CHANGES]: "Request Change",
    [ReviewStatus.COMPLETED]: "Completed",
    [ReviewStatus.NO_REVIEWED]: "Pending for review",
    [ReviewStatus.CHANGES_SUBMITTED]: "Changes Submitted",
  };

  React.useEffect(() => {
    const filters: DropdownItemProps[] = map(
      [
        ReviewStatus.REVIEWED,
        ReviewStatus.REQUEST_CHANGES,
        ReviewStatus.COMPLETED,
        ReviewStatus.NO_REVIEWED,
        ReviewStatus.CHANGES_SUBMITTED,
      ],
      rStatus => ({
        key: rStatus,
        text: reviewLabels[rStatus],
        value: rStatus,
      })
    );
    setReviewStatusOptions(filters);
  }, []);

  const flaggedFilterOptions: DropdownItemProps[] = [{ key: "flagged", text: "Flagged Posts Only", value: "flagged" }];

  React.useEffect(() => {
    setSelectedFilters(current => {
      return {
        ...current,
        coachPostReviewStatus: reviewStatus,
        searchKeywords,
        keyphraseIds: searchKeywords,
        playerIds,
      };
    });
  }, [reviewStatus, searchKeywords, playerIds]);

  React.useEffect(() => {
    if (!hasSavedKeywords) {
      setSelectedFilters(current => {
        return {
          ...current,
          searchKeywords: current?.searchKeywords,
          flagged: false,
        };
      });
    }
  }, [hasSavedKeywords]);

  React.useEffect(() => {
    if (activePage !== 1) {
      setActivePage(1);
    } else {
      debouncedSearch();
    }
  }, [order, orderStatus, searchFunction, listOfKeywords, selectedFilters]);

  React.useEffect(() => {
    debouncedSearch();
  }, [activePage]);

  React.useEffect(() => {
    if (forceRefresh) {
      debouncedSearch();
    }
  }, [forceRefresh]);

  const activeFilters = React.useMemo(() => {
    let filtersCounter = 0;
    if (selectedFilters?.branchIds && selectedFilters?.branchIds?.length > 0) {
      filtersCounter += 1;
    }
    if (selectedFilters?.regionIds && selectedFilters?.regionIds?.length > 0) {
      filtersCounter += 1;
    }
    if (selectedFilters?.stateIds && selectedFilters?.stateIds?.length > 0) {
      filtersCounter += 1;
    }
    if (selectedFilters?.playerIds && selectedFilters?.playerIds?.length > 0) {
      filtersCounter += 1;
    }
    if (selectedFilters?.searchKeywords && selectedFilters?.searchKeywords?.length > 0) {
      filtersCounter += 1;
    }
    if (selectedFilters?.coachPostReviewStatus && selectedFilters?.coachPostReviewStatus?.length > 0) {
      filtersCounter += 1;
    }
    if (selectedFilters?.from || selectedFilters?.until) {
      filtersCounter += 1;
    }
    if (selectedFilters?.flagged) {
      filtersCounter += 1;
    }

    return filtersCounter;
  }, [selectedFilters]);

  React.useEffect(() => {
    PlayersServices.searchSubscribedAllPlayersByTag(coachId).then(response => {
      const validPlayers = response.content.filter(p => p.playerAccount.firstName && p.playerAccount.lastName);
      const players = map(validPlayers, player => ({
        key: player.playerAccount.playerId,
        text: player.playerAccount.firstName + " " + player.playerAccount.lastName,
        value: player.playerAccount.playerId,
      }));

      setPlayerList(players);
    });
  }, [coachId]);

  const search = () => {
    const page = activePage - 1;
    const orderType = order;
    const searchContent = selectedFilters?.searchKeywords || [];
    const searchIds = selectedFilters?.playerIds || playerIds;

    setLoading(true);

    const filterBody: SearchFilter = {
      keyphraseIds: searchContent.length > 0 ? searchContent : [],
      keyphrasePolicies: selectedFilters?.flagged ? [KeywordsType.Blocked, KeywordsType.Flagged] : [],
      playerIds: searchIds,
      coachPostReviewStatus:
        selectedFilters?.coachPostReviewStatus && selectedFilters?.coachPostReviewStatus?.length > 0
          ? selectedFilters?.coachPostReviewStatus
          : undefined,
      until: selectedFilters?.until ? getUntilDateSearchFormat(selectedFilters?.until) : undefined,
      from: selectedFilters?.from ? getDateSearchFormat(selectedFilters?.from) : undefined,
      branchIds: selectedFilters?.branchIds ? selectedFilters?.branchIds : undefined,
      regionIds: selectedFilters?.regionIds ? selectedFilters?.regionIds : undefined,
      stateIds: selectedFilters?.stateIds ? selectedFilters?.stateIds : undefined,
    };

    searchFunction(coachId, `${orderStatus},${orderType}`, filterBody, page)
      .then(response => {
        setTotalPages(response.totalPages ?? 1);
        refreshData(response.content);
      })
      .finally(() => {
        setLoading(false);
      });

    if (page === undefined && updateExportFilters) {
      updateExportFilters(filterBody, order);
    }
  };

  const debouncedSearch = useDebounce(() => {
    search();
  }, 300);

  const nextPage = (_: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps): void => {
    setActivePage(Number(data.activePage));
  };

  const changeOrder = (): void => {
    const newOrder = ascOrder ? "DESC" : "ASC";
    setOrder(newOrder);
  };

  const performExport = async () => {
    setExportLoading(true);
    if (exportFunction) {
      await exportFunction();
    }
    setExportLoading(false);
  };

  return (
    <div>
      <Grid>
        <Grid.Column floated="left" width={9}>
          <Header as="h2">{title}</Header>
        </Grid.Column>
        <Grid.Column width={3}>
          <Button
            data-elm-id={"complianceExportPlayersBtn"}
            className={"rounded complianceExportBtn"}
            secondary
            icon
            loading={exportLoading}
            onClick={() => {
              performExport().catch(console.error);
            }}
          >
            Export
          </Button>
        </Grid.Column>
      </Grid>

      <Menu secondary className={"searchSetFilterMenu"}>
        {/* FILTER BY FLAGGED ... */}
        <CustomDropDownComponent
          defaultValue={selectedFilters?.flagged ? ["flagged"] : []}
          options={flaggedFilterOptions}
          placeholder={"All Posts"}
          multiple={true}
          onChangeHandler={data => {
            setSelectedFilters(currentFilter => {
              return {
                ...currentFilter,
                searchKeywords: currentFilter?.searchKeywords,
                flagged: data.includes("flagged"),
              };
            });
          }}
        />

        {/* FILTER BY Review Status */}
        <CustomDropDownComponent
          defaultValue={selectedFilters?.coachPostReviewStatus}
          options={reviewStatusOptions}
          placeholder={"Review Status"}
          multiple={true}
          onChangeHandler={setReviewStatus}
        />

        {/* FILTER BY PLAYER */}
        {playerList && playerList?.length > 0 && (
          <CustomDropDownComponent
            defaultValue={selectedFilters?.playerIds}
            options={playerList}
            placeholder={"Player"}
            multiple={multiple}
            onChangeHandler={setPlayerIds}
          />
        )}

        <Menu.Item>
          <Button
            data-elm-id={"complianceAllFiltersBtn"}
            secondary
            className={"rounded allFiltersBtn"}
            icon
            loading={exportLoading}
            onClick={() => setDisplayAllFilters(true)}
          >
            All Filters {activeFilters > 0 ? `(${activeFilters})` : ""}
          </Button>
        </Menu.Item>

        {/* SORT BY  */}
        {showSortBy && (
          <Menu.Menu floated="right">
            <Menu.Item>
              <Dropdown
                search
                selection
                placeholder={"Sort by"}
                className="sortInputSet"
                onChange={(_, data) => {
                  setOrderStatus(data.value !== "" ? (data.value as OrderByType) : "createdDate");
                }}
                defaultValue={defaultCustomSort}
                options={customSort}
              />
            </Menu.Item>

            {/* ORDER BY */}
            <Menu.Item className={"menuIcon"} icon onClick={changeOrder}>
              <Icon name={ascOrder ? "arrow up" : "arrow down"} />
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>

      {loading && <Loader active size="large" />}

      {/* List of elements */}
      {!loading && content}

      {pageable && (
        <Grid centered>
          <Pagination
            activePage={activePage}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
            totalPages={totalPages}
            onPageChange={nextPage}
          />
        </Grid>
      )}

      {displayAllFilters && (
        <FilterModal
          opened={displayAllFilters}
          title={"All Filters"}
          coachId={coachId}
          filters={selectedFilters}
          multiple={multiple}
          loading={loading}
          playerList={playerList}
          reviewStatusOptions={reviewStatusOptions}
          keywordsOptions={keywordsOptions}
          hasSavedKeywords={hasSavedKeywords}
          rejectHandler={() => {
            setDisplayAllFilters(false);
          }}
          clearHandler={() => {
            setDisplayAllFilters(false);
            setSelectedFilters(() => {
              return {
                flagged: false,
                coachPostReviewStatus: [],
                branchIds: undefined,
                regionIds: undefined,
                stateIds: undefined,
                from: undefined,
                until: undefined,
                playerIds: [],
                searchKeywords: [],
              };
            });
          }}
          acceptHandler={updatedFilters => {
            console.log("searching", updatedFilters);
            setSelectedFilters(updatedFilters);
            setDisplayAllFilters(false);
          }}
        />
      )}
    </div>
  );
};

export const SearchSetComponent = SearchSetFC;
