import { useState, useEffect, useMemo, useCallback } from "react";
import { KeywordServices } from "../services";
import { KeywordsType, KeywordElement } from "../interfaces";
import useSocialCoachSession from "./useSocialCoachSession";
import * as Yup from "yup";

export const useComplianceChecker = (context?: { playerId?: string | number; coachId?: string | number }) => {
  const scSession = useSocialCoachSession();

  const determinedPlayerId = useMemo(() => {
    return context?.playerId || scSession?.playerId;
  }, [context?.playerId, scSession?.playerId]);

  const determinedCoachId = useMemo(() => {
    return context?.coachId || scSession?.coachId;
  }, [context?.coachId, scSession?.coachId]);

  const [keywords, setKeywords] = useState<KeywordElement[]>([]);

  const keywordsByPolicy = (result: KeywordElement[], policy: KeywordsType): KeywordElement[] => {
    return result.filter(keyword => keyword.policy === policy);
  };

  const blockedKeywords: KeywordElement[] = useMemo(() => {
    if (keywords && keywords.length > 0) {
      return keywordsByPolicy(keywords, KeywordsType.Blocked);
    } else return [];
  }, [keywords]);

  const flaggedKeywords: KeywordElement[] = useMemo(() => {
    if (keywords && keywords.length > 0) {
      return keywordsByPolicy(keywords, KeywordsType.Flagged);
    } else return [];
  }, [keywords]);

  const blockedPhrases: string[] = useMemo(() => {
    return blockedKeywords.map(t => t.content.toLowerCase());
  }, [blockedKeywords]);

  const flaggedPhrases: string[] = useMemo(() => {
    return flaggedKeywords.map(t => t.content.toLowerCase());
  }, [flaggedKeywords]);

  const findWords = (text: string | string[], words: string[]): string[] => {
    const content: string[] = [];

    if (text && text.length > 0) {
      if (Array.isArray(text)) {
        text.forEach(s => {
          if (s.length > 0) content.push(s);
        });
      } else {
        content.push(text);
      }
    }
    return words.filter(phrase => {
      const regex = new RegExp("\\b" + phrase + "\\b", "i");
      return content.some(stringToCheck => {
        return regex.test(stringToCheck);
      });
    });
  };

  const findBlockedWords = useCallback(
    (text: string | string[]): string[] => {
      return findWords(text, blockedPhrases);
    },
    [blockedPhrases]
  );

  const findFlaggedWords = useCallback(
    (text: string | string[]): string[] => {
      return findWords(text, flaggedPhrases);
    },
    [flaggedPhrases]
  );

  const blockedWordsValidator = useMemo(() => {
    return Yup.string()
      .trim()
      .test({
        name: "blockedWords",
        skipAbsent: true,
        test(value, ctx) {
          const found = findBlockedWords(value || "");
          if (found.length > 0) {
            return ctx.createError({ message: "Blocked words detected: " + found.join(", ") });
          }
          return true;
        },
      });
  }, [findBlockedWords]);

  useEffect(() => {
    const getKeywords = async () => {
      if (determinedPlayerId) {
        const result = await KeywordServices.getAsPlayer(determinedPlayerId);
        setKeywords(result);
      } else if (determinedCoachId) {
        const result = await KeywordServices.get(determinedCoachId.toString());
        setKeywords(result);
      }
    };

    getKeywords().catch(console.error);
  }, [determinedPlayerId, determinedCoachId]);

  return {
    findFlaggedWords,
    findBlockedWords,
    blockedPhrases,
    flaggedPhrases,
    blockedKeywords,
    flaggedKeywords,
    setKeywords,
    blockedWordsValidator,
  };
};
