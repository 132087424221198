import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Button, Grid, Loader, Message, Tab } from "semantic-ui-react";
import { get, isEmpty } from "lodash";

import { SideBar, TitleSection, PromptPreview, PlayersSegmentation, ConfirmationModal } from "../../../components";
import { AppContext } from "../../../providers";
import { PromptServices } from "../../../services";
import {
  URLS,
  fullName,
  finishStep,
  coachWithWritePermission,
  capitalizeFormatter,
  useComplianceChecker,
} from "../../../utils";
import {
  Media,
  Prompt,
  PromptPreviewObject,
  PromptStatus,
  PromptRequest,
  EligibilityCriteria,
  WebComponents,
  PromptType,
} from "../../../interfaces";

import { PromptForm } from "./components/PromptForm";
import { modalReducer } from "./welcomeReducer";
import { PromptPageType, descriptors } from "./descriptors";

import { WhiteContainer } from "../../../styling/baseStyle";

import "./styles.scss";
import { HeaderActionButtons } from "./components/HeaderActionButtons";
import {
  validateFeedPromptWithMultimedia,
  validateMediasRequiremenstByPrompt,
  validateSegmentation,
} from "./validations";

type Props = RouteComponentProps;

const PromptFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const writePermission = coachWithWritePermission(WebComponents.LIBRARY, userContext);

  const [state, dispatch] = React.useReducer(modalReducer, {
    open: false,
    status: "ACTIVE",
    duplicated: false,
    action: "close",
  });

  // Status properties
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [formErrorMessages, setFormErrorMessages] = React.useState<string[] | undefined>(undefined);

  const [ready, setReady] = React.useState<boolean>(false);
  const [uploadingContent, setUploadingContent] = React.useState(false);

  // Prompt info properties
  const [prompt, setPrompt] = React.useState<PromptPreviewObject | undefined>(undefined);
  const [originalPrompt, setOriginalPrompt] = React.useState<PromptPreviewObject | undefined>(undefined);

  const promptActive: boolean = prompt ? prompt!.status === "ACTIVE" : false;

  // Tab properties
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  // Segmentation section
  const segmentation = get(prompt, "eligibilityCriteria[0]", undefined);
  const [isSegmented, setIsSegmented] = React.useState<boolean>(false);
  const [segmentedPlayers, setSegmentedPlayers] = React.useState<number[]>(
    get(segmentation, "values", []).map((i: string) => Number(i))
  );
  const [eligibilityCriteria, setEligibilityCriteria] = React.useState<EligibilityCriteria[] | undefined>(undefined);
  const [showSegmentationError, setShowSegmentationError] = React.useState<boolean>(false);

  // Messages
  const titleMessage: string = formatMessage({
    ...descriptors[editMode ? PromptPageType.titleEdit : PromptPageType.title],
  });
  const saveButtonTitle: string = formatMessage({
    ...descriptors[PromptPageType[promptActive ? "saveBtn" : "savePublishBtn"]],
  });
  const generalError = formatMessage({
    ...descriptors[PromptPageType[showSegmentationError ? "segmentationRequired" : "unknownError"]],
  });

  // Blocked Words
  const { findBlockedWords } = useComplianceChecker({ coachId });
  const blockedWords: string[] = React.useMemo(() => {
    if (!prompt) {
      return [];
    }

    const content: string[] = [];

    if (prompt.message && prompt.message.length > 0) {
      content.push(prompt.message);
    }

    if (prompt.post?.media) {
      prompt.post.media.forEach(media => {
        media?.transcripts?.forEach(transcript => {
          if (transcript?.content && transcript?.content.length > 0) {
            content.push(transcript.content);
          }
        });
      });
    }

    return findBlockedWords(content);
  }, [prompt, findBlockedWords]);

  const getErrors = (): string[] | undefined => {
    const errors: any[] = [];
    const validMedias = validateMediasRequiremenstByPrompt(prompt);

    if (!prompt?.title) {
      errors.push(formatMessage({ ...descriptors[PromptPageType.attributeRequired] }, { attribute: "Title" }));
    }

    if (blockedWords && blockedWords?.length > 0) {
      errors.push(
        formatMessage(
          {
            ...descriptors[PromptPageType.wrongMessage],
          },
          {
            blockedwords: blockedWords.join(", "),
          }
        )
      );
    }

    if (!validMedias) {
      errors.push(
        formatMessage({
          ...descriptors[PromptPageType.wrongSpecificationsMessage],
        })
      );
    }

    if (validateFeedPromptWithMultimedia(prompt)) {
      const someFailed = prompt?.post?.media.some(
        media => !media.validAspectRatio && media.validAspectRatio !== undefined
      );
      if (someFailed) {
        errors.push(
          formatMessage({
            ...descriptors[PromptPageType.wrongFeedMultipleImagesMessage],
          })
        );
      }
    } else {
      prompt?.post?.media.forEach(media => {
        if (!media.validAspectRatio && media.validAspectRatio !== undefined) {
          errors.push(
            formatMessage(
              {
                ...descriptors[
                  prompt!.type === PromptType.FEED
                    ? PromptPageType.wrongAspectRatioMessage
                    : PromptPageType.wrongAspectRatioReelsMessage
                ],
              },
              {
                type: media.type.split("/")[0],
                typeAfterDot: capitalizeFormatter(media.type.split("/")[0]),
              }
            )
          );
        }

        if (prompt!.type === PromptType.REELS && !media.validDimension) {
          errors.push(
            formatMessage({
              ...descriptors[PromptPageType.wrongResolutionMessage],
            })
          );
        }

        if (media.errors?.wrongBitrate) {
          errors.push(
            formatMessage({
              ...descriptors[PromptPageType.wrongBitrateMessage],
            })
          );
        }
        if (media.errors?.wrongLength) {
          errors.push(
            formatMessage({
              ...descriptors[PromptPageType.wrongDurationoMessage],
            })
          );
        }
        if (media.errors?.wrongSize) {
          errors.push(
            formatMessage(
              {
                ...descriptors[PromptPageType.wrongSizeMessage],
              },
              {
                type: media.type.split("/")[0],
                typeAfterDot: capitalizeFormatter(media.type.split("/")[0]),
              }
            )
          );
        }
      });
    }

    return errors.length > 0 ? errors : undefined;
  };

  React.useEffect(() => {
    setShowSegmentationError(isSegmented && segmentedPlayers.length === 0);
  }, [segmentedPlayers.length, isSegmented]);

  React.useEffect(() => {
    if (params["promptId"]) {
      setEditMode(true);
      setLoading(true);
      PromptServices.get(params["promptId"])
        .then((response: any) => {
          response = setMediaResourceIds(response);
          setPrompt(response);
          setOriginalPrompt(response);
          const defaultSegmentation = get(response, "eligibilityCriteria[0]", undefined);
          setIsSegmented(defaultSegmentation);
          setEligibilityCriteria(() => get(response, "eligibilityCriteria", undefined));
          setSegmentedPlayers(() => get(defaultSegmentation, "values", []).map((i: string) => Number(i)));
        })
        .catch(response => {
          if (response === "This prompt was deleted") {
            alert(response);
            history.push(URLS.coach.library);
          }
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setEditMode(false);
      setLoading(false);
      setError(false);
    }
  }, [params["promptId"]]);

  React.useEffect(() => {
    setFormErrorMessages(() => getErrors());
  }, [prompt, blockedWords]);

  /**
   * This is a temporary solution to the sortOrder vs. ID problem.
   * Media items need a sortOrder to compose the post correctly. The
   * ID is a constant, locally-unique identifier to allow
   * react-beautiful-dnd Drag and Drop functionality to work correctly.
   *
   * @param prompt the prompt we loaded from the database
   */
  const setMediaResourceIds = (fetchedPrompt: Prompt) => {
    let nextId = 0;
    const media = get(fetchedPrompt, "post.media", []);
    const newMedia = media.map((item: Media) => {
      nextId = item.id || nextId + 1;
      return {
        ...item,
        id: nextId,
        validAspectRatio: true,
        validDimension: true,
      };
    });
    return {
      ...fetchedPrompt,
      post: {
        ...fetchedPrompt.post,
        media: newMedia,
      },
    };
  };

  const savePrompt = async (status: PromptStatus, redirectPath?: string): Promise<any> => {
    try {
      // Segmentation validation
      const segmentationIsOK = validateSegmentation(isSegmented, eligibilityCriteria);
      const validMedia = validateMediasRequiremenstByPrompt(prompt);
      const withMessage = get(prompt, "message", "") + get(prompt, "hashtags", "") !== "";
      const withMedia = get(prompt, "post.media.length", 0);
      if (
        prompt?.title &&
        validMedia &&
        segmentationIsOK &&
        (withMessage || withMedia > 0) &&
        blockedWords?.length === 0
      ) {
        const {
          title,
          message,
          richMessage,
          hashtags,
          allowHashtags,
          allowShortLink,
          type,
          allowComplianceDisclaimer,
        } = prompt;

        const categoryIds: string[] | undefined = prompt!.categories!
          ? prompt!.categories!.map(category => {
              return category.categoryId!;
            })
          : undefined;

        const promptToSave: PromptRequest = {
          title,
          message,
          richMessage,
          hashtags,
          deliveryMode: "LIBRARY",
          categoryIds,
          status,
          allowHashtags,
          allowShortLink,
          allowComplianceDisclaimer,
          type,
        };

        setLoading(true);
        setError(false);
        setFormErrorMessages(undefined);

        if (!isEmpty(prompt!.media)) {
          promptToSave.mediaMessage = {
            media: prompt!.media!,
            mediaGroupId: prompt!.mediaMessage?.mediaGroupId,
            type: "VIDEO",
          };
        }

        if (prompt!.post && !isEmpty(prompt!.post!.media)) {
          promptToSave.post = prompt!.post;
        }

        if (isSegmented && eligibilityCriteria && !isEmpty(eligibilityCriteria)) {
          promptToSave.eligibilityCriteria = eligibilityCriteria;
        }

        let savedPrompt;
        if (prompt?.promptId) {
          promptToSave.promptId = prompt?.promptId;
          savedPrompt = await PromptServices.update(promptToSave, userContext!.coach!.id);
        } else {
          savedPrompt = await PromptServices.create(promptToSave, userContext!.coach!.id);
        }

        setReady(true);
        if (redirectPath) {
          const finalPath = redirectPath.replace(":promptId", `${savedPrompt?.promptId}`);
          history.push(finalPath);
        } else {
          const finalPath = URLS.coach.prompt.replace(":promptId", `${savedPrompt?.promptId}`);
          alert("Prompt has been saved!");
          history.replace(finalPath);
        }
        setLoading(false);
      } else {
        setFormErrorMessages(() => getErrors());
      }
    } catch (e: any) {
      setFormErrorMessages([e]);
    }
  };

  const disablePrompt = async (): Promise<any> => {
    try {
      if (originalPrompt?.title) {
        const categoryIds: string[] | undefined = originalPrompt!.categories!
          ? originalPrompt!.categories!.map(category => {
              return category.categoryId!;
            })
          : undefined;

        const promptToSave: PromptRequest = {
          ...originalPrompt,
          title: originalPrompt!.title,
          categoryIds,
          categories: undefined,
          post: undefined,
          status: "DISABLED",
        };

        if (!isEmpty(originalPrompt!.media)) {
          promptToSave.mediaMessage = {
            media: originalPrompt!.media!,
            mediaGroupId: originalPrompt!.mediaMessage?.mediaGroupId,
            type: "VIDEO",
          };
        }

        if (originalPrompt!.post && !isEmpty(originalPrompt!.post!.media)) {
          promptToSave.post = originalPrompt!.post;
        }
        setLoading(true);
        setError(false);
        setFormErrorMessages(undefined);

        await PromptServices.update(promptToSave, userContext!.coach!.id)
          .then(() => {
            setReady(true);
            history.push(URLS.coach.library);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setFormErrorMessages(() => getErrors());
      }
    } catch (e: any) {
      setFormErrorMessages([e]);
    }
  };
  const verifyAndSave = (status: PromptStatus): void => {
    if (status === "DISABLED") {
      if (window.confirm("Do you want to remove this prompt?")) {
        disablePrompt();
      }
    } else {
      savePrompt(status);
    }
  };

  const verifySaveAndPost = (): void => {
    savePrompt("ACTIVE", URLS.coach.post);
  };

  const segmentationOnChangeHandler = (playersId: number[], isInclude: boolean) => {
    setSegmentedPlayers(playersId);
    setEligibilityCriteria(() => {
      return [
        {
          values: playersId,
          level: "PLAYER_ID",
          isInclude,
        },
      ];
    });
  };

  const closeAndGoToLibrary = () => {
    if (state.action === "cancel") {
      history.push(URLS.coach.library);
    } else {
      dispatch({ type: "close" });
    }
  };

  const cancelAction = () => {
    dispatch({
      type: "open_cancel",
      status: promptActive ? "ACTIVE" : "DRAFT",
    });
  };

  const confirmationModal = (): JSX.Element => {
    const modalTitle = formatMessage({
      ...descriptors[
        state.action === "cancel" ? PromptPageType.cancelAlertTitle : PromptPageType.welcomePromptAlertTitle
      ],
    });
    return (
      <ConfirmationModal
        title={!state.duplicated ? modalTitle : undefined}
        message={""}
        openConfirmationModal={state.open}
        onClose={() => dispatch({ type: "close" })}
        okHandler={() => {
          dispatch({ type: "close" });
          savePrompt(state.status);
        }}
        rejectHandler={closeAndGoToLibrary}
      />
    );
  };

  const customLinkSection = (disableButtons: boolean): JSX.Element => (
    <HeaderActionButtons
      promptActive={promptActive}
      disableButtons={disableButtons}
      goBack={cancelAction}
      isANewPrompt={!prompt?.promptId}
      availableToPost={true}
      verifyAndSave={verifyAndSave}
    />
  );

  const customNextSection = (): JSX.Element => {
    const nextButtonSize = 8;
    const sectionSize = promptActive ? 9 : 10;
    const segmentationIsOK = validateSegmentation(isSegmented, eligibilityCriteria);
    const validMedia = validateMediasRequiremenstByPrompt(prompt);
    const withMessage = get(prompt, "message", "") + get(prompt, "hashtags", "") !== "";
    const withMedia = get(prompt, "post.media.length", 0);
    const promptReady =
      prompt?.title && validMedia && segmentationIsOK && (withMessage || withMedia > 0) && blockedWords?.length === 0;
    const disableButtons = uploadingContent || !promptReady;
    const nextButtonLabel = saveButtonTitle;

    return (
      <Grid.Column width={sectionSize}>
        <Grid.Row>
          <Grid>
            <Grid.Column floated="right" width={nextButtonSize}>
              <Button
                floated="right"
                disabled={disableButtons || loading}
                fluid
                onClick={() => {
                  verifyAndSave("ACTIVE");
                }}
                className={"primary rounded saveButton"}
                data-elm-id={"promptTitleSectionSaveBtn"}
                loading={loading}
              >
                {nextButtonLabel}
              </Button>
            </Grid.Column>

            <Grid.Column floated="right" width={nextButtonSize}>
              <Button
                floated="right"
                disabled={disableButtons || loading}
                fluid
                onClick={verifySaveAndPost}
                className={"primary rounded saveButton"}
                data-elm-id={"titleSectionSaveAndPostBtn"}
                loading={loading}
              >
                {formatMessage({ ...descriptors[PromptPageType.savePostBtn] })}
              </Button>
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Column>
    );
  };

  const tabOnChangeHandler = (nextIndex: number) => {
    const errors = getErrors();
    if (errors || showSegmentationError) {
      setFormErrorMessages(errors);
    } else {
      setSelectedIndex(nextIndex || 0);
    }
  };

  const panes = [
    {
      menuItem: "Prompt Info",
      render: () => (
        <Tab.Pane className={"infoSection"} loading={loading}>
          <Grid.Column width={10} className={"formContainer"}>
            {!loading && (
              <PromptForm
                disabled={!writePermission}
                modeEditable={!editMode}
                prompt={prompt}
                onContentloading={setUploadingContent}
                updatingHandler={setPrompt}
                pageErrors={formErrorMessages}
                coachId={userContext?.coach?.id!!}
              />
            )}
          </Grid.Column>
        </Tab.Pane>
      ),
    },
    {
      menuItem: formatMessage({
        ...descriptors[PromptPageType.segmentationSectionTitle],
      }),
      render: () => (
        <Tab.Pane className={"segmentationSection"} loading={loading}>
          <PlayersSegmentation
            disabled={!writePermission}
            segmentedCampaign={isSegmented}
            selectedPlayers={segmentedPlayers}
            parentResource={"prompt"}
            onChange={segmentationOnChangeHandler}
            isSegmentedOnChange={setIsSegmented}
            criteria={get(eligibilityCriteria, "[0].isInclude", true) ? "include" : "exclude"}
          />
        </Tab.Pane>
      ),
    },
  ];

  const promptContent = (): JSX.Element => {
    return (
      <Grid columns={1} className={"contentSection"}>
        {confirmationModal()}
        <TitleSection
          title={titleMessage}
          ready={finishStep(userContext?.signUpStep)}
          customLinkSection={customLinkSection(uploadingContent)}
          customNextLabel={saveButtonTitle}
          customNextSection={customNextSection()}
          disableButtons={uploadingContent}
          titleSize={4}
          buttonsSize={11}
          nextButtonSize={prompt?.promptId ? 7 : 8}
          showLinkOption={!loading && writePermission}
          showNextOption={!loading && writePermission}
        />
        {error || (showSegmentationError && <Message error content={generalError} />)}

        <Grid.Row columns={2} className={"formSection leftPadding"}>
          <Tab
            panes={panes}
            activeIndex={selectedIndex}
            onTabChange={(e, data) => {
              tabOnChangeHandler(Number(data.activeIndex!));
            }}
          />
          <Grid.Column width={6} className={"previewSection"}>
            {/* DREWTODO: pass in array of media urls, not just one */}
            {prompt && selectedIndex === 0 && (
              <PromptPreview
                title={prompt.title}
                message={prompt.richMessage || prompt.message}
                hashtags={prompt.hashtags}
                date={""}
                showVideo={prompt.media ? true : false}
                videoUrl={get(prompt, "media[0].uri", undefined)}
                showAttached={prompt.post ? true : false}
                showShortLink={prompt.allowShortLink}
                showComplianceDisclaimer={prompt.allowComplianceDisclaimer}
                coachFullName={fullName(userContext)}
                media={get(prompt, "post.media", [])}
                coachImage={userContext?.profile.photoUrl}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} readyToContinue={ready || !writePermission} />
      {loading && (prompt!.promptId || !editMode) ? <Loader active size="large" /> : promptContent()}
    </WhiteContainer>
  );
};

export const PromptPage = withRouter(PromptFC);
