export enum CoachSettings {
  CUSTOM_STICKERS = "CUSTOM_STICKERS",
  CUSTOMIZABLE_RANDOM_CAMPAIGN = "CUSTOMIZABLE_RANDOM_CAMPAIGN",
  POST_CUSTOM_IMAGES = "POST_CUSTOM_IMAGES",
  POST_CUSTOM_MESSAGE = "POST_CUSTOM_MESSAGE",
  PLAYER_STICKERS = "PLAYER_STICKERS",
  POST_IN_FB = "POST_IN_FB",
  POST_IN_IG = "POST_IN_IG",
  POST_IN_TW = "POST_IN_TW",
  POST_IN_TT = "POST_IN_TT",
  POST_IN_LI = "POST_IN_LI",
  POST_IN_IG_STORY = "POST_IN_IG_STORY",
  CAMPAIGN_DEFAULT_SN = "CAMPAIGN_DEFAULT_SN",
  PLAYER_HASHTAG = "PLAYER_HASHTAG",
  PLAYER_CUSTOM_STICKERS = "PLAYER_CUSTOM_STICKERS",
  PLAYER_NOTIFY_FAILED_POST = "PLAYER_NOTIFY_FAILED_POST",
  SUMMARY_REPORT_PLAYER = "SUMMARY_REPORT_PLAYER",
  RECURRING_ONBOARDING_NOTIFICATIONS = "RECURRING_ONBOARDING_NOTIFICATIONS",
  RECURRING_SOCIAL_CONNECT_NOTIFICATIONS = "RECURRING_SOCIAL_CONNECT_NOTIFICATIONS",
  COMPLIANCE_DISCLAIMER = "COMPLIANCE_DISCLAIMER",
  COMPLIANCE_DISCLAIMER_CONTENT_PAGES = "COMPLIANCE_DISCLAIMER_CONTENT_PAGES",
  NOTIFY_PLAYER_VIDEO_PROJECT_EDITED = "NOTIFY_PLAYER_VIDEO_PROJECT_EDITED",
  PLAYER_MVP_ACCESS = "PLAYER_MVP_ACCESS",
}
export interface AppSetting {
  appSettingId: CoachSettings;
  status: string; // "ACTIVE" | "INACTIVE";
}

export interface CoachAppSetting {
  coachId: string;
  settings: AppSetting[];
}

export interface PlayerAppSetting {
  coachId: string;
  playerId: string;
  settings: AppSetting[];
}

export enum WebSettings {
  EXTERNAL_POST = "EXTERNAL_POST",
  FAILED_POST_NOTIFICATION = "FAILED_POST_NOTIFICATION",
  TIKTOK = "TIKTOK",
  SUMMARY_REPORT_COACH = "SUMMARY_REPORT_COACH",
  COST_CENTER = "COST_CENTER",
  SC_PLUS_TIER = "SC_PLUS_TIER",
  ACCESS_SCRIPT_LIBRARY = "ACCESS_SCRIPT_LIBRARY",
  MANAGE_SCRIPT_LIBRARY = "MANAGE_SCRIPT_LIBRARY",
  ACCOUNT_MVP_ACCESS = "ACCOUNT_MVP_ACCESS",
  CALENDAR_ACCESS = "CALENDAR_ACCESS",
}

export interface WebSetting {
  webSettingId: WebSettings;
  status: string; // "ACTIVE" | "INACTIVE";
}

export interface AdminWebSetting {
  coachId: string;
  settings: WebSetting[];
}
